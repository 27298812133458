@import '../../../../styles/mixins/default-picture';
@import '../../../../styles/basics';

.bb-gifts-banner {
  @include bb-standalone-spacing-new();

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 8px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 8px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  &__image {
    padding: 2px;
    :global {
      @include default-picture();
    }
  }
  &__content {
    padding: 32px 16px 16px;
  }

  &__main_icon,
  &__sub_icon {
    display: none;
  }

  &__tag {
    display: inline-block;
    margin-bottom: 18px;
    padding: 2px 8px;
    font-weight: bold;
    color: $white;
  }
  &__heading {
    color: $black;
    margin-bottom: 8px;
    font-size: 28px;
  }
  &__subheading {
    color: $black;
    margin-bottom: 24px;
    font-size: 18px;
  }
  &__button {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      gap: 24px;
      flex-direction: row;
      padding: 16px;
    }
    &__content {
      padding: 40px 0 0;
    }
    &__image {
      flex-shrink: 0;
      padding: 0;
      width: 332px;
    }
    &__sub_icon {
      display: block;
      position: absolute;
      width: 331px;
      top: 34px;
      right: -280px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      gap: 48px;
    }
    &__heading {
      font-size: 36px;
    }
    &__sub_icon {
      right: -124px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__sub_icon {
      right: 84px;
    }
  }

  @media (min-width: 1400px) {
    &__image {
      width: 261px;
    }
    &__subheading,
    &__heading {
      padding-right: 180px;
    }
    &__main_icon {
      display: block;
      position: absolute;
      width: 176px;
      top: 48px;
      right: 37px;
    }
    &__sub_icon {
      top: 25px;
      right: 245px;
    }
  }
}

.bb-gifts-banner__balloons {
  .bb-gifts-banner {
    &__wrapper {
      background: $turquoise-200;
    }
    &__sub_icon path,
    &__main_icon path {
      fill: $deep-blue-500;
    }
    &__tag {
      background: $deep-blue-500;
    }
  }
}

.bb-gifts-banner__hearts {
  .bb-gifts-banner {
    &__wrapper {
      background: $yellow-100;
    }
    &__sub_icon path,
    &__main_icon path {
      fill: $yellow-800;
    }
    &__tag {
      color: $black;
      background: $yellow-800;
    }
  }
}

.bb-gifts-banner__flowers {
  .bb-gifts-banner {
    &__wrapper {
      background: $turquoise-200;
    }
    &__sub_icon path,
    &__main_icon path {
      fill: $turquoise-1000;
    }
    &__tag {
      background: $turquoise-1000;
    }
  }
}